<template>
  <div
    v-if="resultDataFilteredByClick.length"
    class="risk-exposure__table"
  >
    <RiskExposureRow
      v-for="item in resultDataFilteredByClick"
      :key="item.name"
      :full-width="findMaxShare"
      :row-data="item"
      :type-of-funds="typeOfFunds"
      @row-click="rowClick(item)"
    />
  </div>
  <div
    v-else
    class="risk-exposure__empty"
  >
    <div>There is no data yet.</div>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapMutations } from 'vuex'

// components
import RiskExposureRow from './RiskExposureRow'

// utils
import smoothReflow from 'vue-smooth-reflow'

export default {
  components: {
    RiskExposureRow,
  },
  mixins: [smoothReflow],
  props: {
    exposureData: {
      type: Array,
      default: () => [],
    },
    typeOfFunds: {
      type: String,
      default: 'sof',
    },
    fake: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveSort', 'getActiveRowName']),
    findMaxShare() {
      let maxShare = 0

      for (const item of this.exposureData) {
        const shareSum = item.deepDepthShare + item.generalShare
        if (shareSum > maxShare) {
          maxShare = shareSum
        }
      }

      return Math.round(maxShare * 100)
    },
    resultDataSorted() {
      return this.exposureData.slice().sort((a, b) => {
        if (this.getActiveSort === 'hamount') {
          return b.share - a.share
        } else if (this.getActiveSort === 'lamount') {
          return a.share - b.share
        } else if (this.getActiveSort === 'hrisk') {
          return b.score - a.score;
        } else if (this.getActiveSort === 'lrisk') {
          return a.score - b.score;
        } else {
          return this.resultData
        }
      })
    },
    resultDataFilteredByClick() {
      if (this.getActiveRowName) {
        return this.resultDataSorted.filter(item => item.name === this.getActiveRowName)
      } else {
        return this.resultDataSorted
      }
    },
  },
  watch: {
    resultDataFilteredByClick: {
      handler(newFilteredExposure) {
        if (this.typeOfFunds === 'sof') {
          this.SET_SOF_EXPOSURE(newFilteredExposure || [])
        } else if (this.typeOfFunds === 'uof') {
          this.SET_UOF_EXPOSURE(newFilteredExposure || [])
        }
      },
      immediate: true,
    },
  },
  mounted(){
    this.$smoothReflow({
      property: ['height'],
      transition: 'height 0.4s ease-in-out'
    })
  },
  methods: {
    ...mapMutations({
      SET_ACTIVE_ROW_NAME: 'report/SET_ACTIVE_ROW_NAME',
      SET_SOF_EXPOSURE: 'report/SET_SOF_EXPOSURE',
      SET_UOF_EXPOSURE: 'report/SET_UOF_EXPOSURE',
    }),
    rowClick(item) {
      if(!this.fake) {
        if(this.getActiveRowName) {
          this.SET_ACTIVE_ROW_NAME(null)
        } else if(this.getActiveRowName != item.name) {
          this.SET_ACTIVE_ROW_NAME(item.name)
        }
      }
    },
  }
}
</script>

<style>
.risk-exposure__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  color: #595959;
}
</style>