<template>
  <div class="landing-page-features">
    <div
      class="landing-page__background landing-page__background--features-left"
    >
      <img
        alt="background main"
        src="~@/assets/images/background.png"
      >
    </div>
    <div
      class="landing-page__background landing-page__background--features-right"
    >
      <img
        alt="background main"
        src="~@/assets/images/background.png"
      >
    </div>
    <div class="landing-page-features-head-text">
      Features
    </div>
    <div class="landing-page-features-sub-head-text">
      We provide unmatched solutions
    </div>
    <div class="flex column gap-30 white-text">
      <div class="flex l-display-block gap-30 main-text">
        <div
          class="landing-page-features-card landing-page-features-card-type flex-1"
        >
          <div class="mb-48 l-mb-4">
            <div class="fs-42 l-fs-24 bold-700 line-height-125pc mb-4 l-mb-3">
              Ensure Your Received Crypto is Clean
            </div>
            <div class="fs-18 bold line-height-150pc mb-48 l-mb-3">
              Stay compliant and avoid legal issues by assessing the risk
              associated with your received cryptocurrencies.
            </div>
            <div>
              <RiskExposure
                fake
                :risk-labels-data="dataForTypes"
                type-of-funds="sof"
              />
            </div>
          </div>

          <div
            class="landing-page-features-card-type-action accent-text-link"
            @click="
              scrollToSelector('.main', '.landing-page-check-report-wrapper', 200)
            "
          >
            <div> Check crypto now </div> <i class="pi pi-angle-right" />
          </div>
        </div>
        <div
          class="landing-page-features-card landing-page-features-card-entity flex-1"
        >
          <div class="mb-48 l-mb-4">
            <div class="fs-42 l-fs-24 bold-700 line-height-125pc mb-4 l-mb-3">
              Track and Trace Stolen Crypto
            </div>
            <div class="fs-18 bold line-height-150pc mb-48 l-mb-3">
              Recover your stolen crypto assets with the help of Satoshi Eye's
              advanced tracking capabilities.
            </div>
            <div>
              <RiskExposure
                fake
                :risk-labels-data="dataForEntitys"
                type-of-funds="uof"
              />
            </div>
          </div>

          <div class="landing-page-features-card-entity-action">
            COMING SOON
          </div>
        </div>
      </div>
      <div class="landing-page-features-card landing-page-features-card-report">
        <div class="flex-1">
          <div class="landing-page-features-card-report-head-text">
            All Details Report
          </div>
          <div
            class="landing-page-features-card-report-text mb-60 l-mb-4 max-w-450"
          >
            Get every detail of all the sources of funds for total peace of mind.
          </div>
          <div class="landing-page-features-card-report-action l-mb-4">
            <div
              @click="
                scrollToSelector('.main', '.landing-page-check-report-wrapper', 200)
              "
            >
              Get All Details report
            </div>
            <i class="pi pi-angle-right" />
          </div>
        </div>
        <div class="flex-1">
          <img
            class="landing-page-features-card-report-image"
            src="~@/assets/svg/landing/report-cards.png"
          >
        </div>
      </div>
      <div class="flex l-display-block gap-30">
        <div
          class="landing-page-features-card landing-page-features-card-soon landing-page-features-card-soon--back1 flex-1"
        >
          <div class="landing-page-features-card-soon-action">
            COMING SOON
          </div>
          <div class="landing-page-features-card-soon-head-text">
            Monitor Transactions in Real-Time
          </div>
          <div class="landing-page-features-card-soon-text">
            Stay compliant and avoid legal issues by assessing the risk
            associated with your received cryptocurrencies.
          </div>
        </div>
        <div
          class="landing-page-features-card landing-page-features-card-soon landing-page-features-card-soon--back2 flex-1"
        >
          <div class="landing-page-features-card-soon-action">
            COMING SOON
          </div>
          <div class="landing-page-features-card-soon-head-text max-w-400">
            Personal Alert System
          </div>
          <div class="landing-page-features-card-soon-text">
            Customize your alert preferences to receive notifications based on
            your unique risk tolerance.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import RiskExposure from '@/pages/report/components/redesign/RiskExposure'
//Utils
import { scrollToSelector } from '@/utils/scroll-to'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
//vuex
import { mapState } from 'vuex'

export default {
  components: {
    RiskExposure,
  },
  mixins: [deviceWidthMixin],
  computed: {
    ...mapState('analytics', ['coinData']),
    headerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height',
      )
    },
    basisPercentGraphWidth() {
      if (this.isMobile) return 100
      if (this.isTablet) return 40
      return 50
    },
    isBtc() {
      return this.coinData?.family == 'btc'
    },
    dataForTypes() {
      return {
        data: [
          {
            amount: this.isBtc ? 3125712323 : this.fakeDecimals(31257123233125712323),
            price: this.isBtc ? 2096319.93 : 97693.49,
            deepDepthShare: 0.18,
            generalShare: 0.14,
            score: 0,
            name: 'Low-risk Exchange',
          },
          {
            amount: this.isBtc ? 1228439523 : this.fakeDecimals(12284395231228439523),
            price: this.isBtc ? 823871.15 : 37816.83,
            deepDepthShare: 0,
            generalShare: 0.12,
            score: 65,
            name: 'Mixing',
          },
          {
            amount: this.isBtc ? 713162427 : this.fakeDecimals(713162427713162427),
            price: this.isBtc ? 478254.78 : 2205.98,
            deepDepthShare: 0,
            generalShare: 0.07,
            score: 100,
            name: 'Darknet Marketplace',
          },
          {
            amount: this.isBtc ? 427165987 : this.fakeDecimals(427165987427165987),
            price: this.isBtc ? 286443.16 : 1240,
            deepDepthShare: 0,
            generalShare: 0.045,
            score: 50,
            name: 'P2P Exchange',
          },
          {
            amount: this.isBtc ? 412161397 : this.fakeDecimals(412161397412161397),
            price: this.isBtc ? 276383.11 : 1104.56,
            deepDepthShare: 0,
            generalShare: 0.04,
            score: 10,
            name: 'Mining / Wallet',
          },
        ]
      }
    },
    dataForEntitys() {
      return {
        data: [
          {
            amount: this.isBtc ? 3125712323 : this.fakeDecimals(31257123233125712323),
            price: this.isBtc ? 2096319.93 : 97693.49,
            deepDepthShare: 0.18,
            generalShare: 0.14,
            score: 0,
            name: 'Low-risk Exchange',
          },
          {
            amount: this.isBtc ? 1228439523 : this.fakeDecimals(12284395231228439523),
            price: this.isBtc ? 823871.15 : 37816.83,
            deepDepthShare: 0,
            generalShare: 0.12,
            score: 65,
            name: 'P2P Exchange',
          },
          {
            amount: this.isBtc ? 713162427 : this.fakeDecimals(713162427713162427),
            price: this.isBtc ? 478254.78 : 2205.98,
            deepDepthShare: 0,
            generalShare: 0.07,
            score: 100,
            name: 'Darknet Marketplace',
          },
          {
            amount: this.isBtc ? 427165987 : this.fakeDecimals(427165987427165987),
            price: this.isBtc ? 286443.16 : 1240,
            deepDepthShare: 0,
            generalShare: 0.045,
            score: 50,
            name: 'Bridge',
          },
          {
            amount: this.isBtc ? 412161397 : this.fakeDecimals(412161397412161397),
            price: this.isBtc ? 276383.11 : 1104.56,
            deepDepthShare: 0,
            generalShare: 0.04,
            score: 10,
            name: 'Mixing Service',
          },
        ]
      }
    }
  },
  methods: {
    scrollToSelector,
    fakeDecimals(amount) {
      return parseFloat(amount.toString().slice(0, this.coinData?.decimals + 2))
    }
  },
}
</script>

<style>
.landing-page-features {
  display: flex;
  flex-direction: column;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  position: relative;
  z-index: 2;
}
.landing-page-features-head-text {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-text-50);
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
}
.landing-page-features-sub-head-text {
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: var(--dark-text);
  margin-bottom: 90px;
  position: relative;
  z-index: 2;
}
.landing-page-features-card {
  border-radius: 24px;
  box-shadow: 0px 0px 10px 0px var(--dark-transparent-1a);
  padding: 60px;
  background-color: var(--white);
  position: relative;
  z-index: 2;
}

.landing-page-features-card-report {
  position: relative;
  display: flex;
  gap: 30px;
  background-color: var(--warning);
}

.landing-page-features-card-report-image {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all 1.5s;
}

.landing-page-features-card-report:hover
  .landing-page-features-card-report-image {
  transform: translate(-3%, -3%) scale(1.06);
  transition: all 1.5s;
}

.landing-page-features-card-report:hover
  .landing-page-features-card-report-action {
  color: var(--warning);
  background: var(--white);
  transition: all 0.5s;
}
.landing-page-features-card-type:hover .landing-page-features-card-type-action {
  color: var(--white);
  background: var(--reflex-bluet);
  transition: all 0.5s;
  user-select: none;
}

.landing-page-features-card-report-action,
.landing-page-features-card-type-action {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  -webkit-transition: background 1s;
  transition: background 1s;
  user-select: none;
}
.landing-page-features-card-report-action i,
.landing-page-features-card-type-action i {
  margin-left: 8px;
  font-size: 22px;
}

.landing-page-features-card-report-report-cards {
  background-image: url('~@/assets/svg/landing/report-cards.png');
}

.landing-page-features-card-soon {
  background-color: var(--reflex-bluet);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}
.landing-page-features-card-soon--back1 {
  background-image: url('~@/assets/images/back-monitor.png');
}
.landing-page-features-card-soon--back2 {
  background-image: url('~@/assets/images/back-personal.png');
}
.landing-page-features-card-soon-action {
  border: 1px solid var(--white);
  width: fit-content;
  padding: 8px 16px;
  border-radius: 39px;
  background: #ffffff40;
  margin-bottom: 20px;
  transition: 0.3s all;
  transition-delay: 0.1s;
  user-select: none;
}
.landing-page-features-card-soon:hover .landing-page-features-card-soon-action {
  transform: translateX(10%) scale(1.16);
}

.landing-page-features-card-soon-head-text,
.landing-page-features-card-report-head-text {
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 30px;
}
.landing-page-features-card-soon-text,
.landing-page-features-card-report-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

/* .landing-page-features-card-entity:hover
  .landing-page-features-card-entity-action {
  background: var(--reflex-bluet);
  color: var(--white);
  transition: 0.5s all;
  user-select: none;
} */

.landing-page-features-card-type,
.landing-page-features-card-entity {
  max-width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landing-page-features-card-entity-action {
  color: var(--reflex-bluet);
  font-weight: 500;
  font-size: 18px;
  padding: 13px 16px;
  width: fit-content;
  border-radius: 39px;
  border: 1px solid var(--reflex-bluet);
  transition: 1s all;
}

@media (max-width: 1279px) {
  .landing-page-features-head-text {
    margin-bottom: 16px;
    font-size: 24px;
    text-align: left;
  }
  .landing-page-features-sub-head-text {
    margin-bottom: 24px;
    font-size: 32px;
    text-align: left;
  }
  .landing-page-features-card {
    border-radius: 8px;
    padding: 24px;
  }
  .landing-page-features-card + .landing-page-features-card {
    margin-top: 24px;
  }
  .landing-page-features-card-type .landing-page-features-card-type-action {
    color: var(--white);
    background: var(--reflex-bluet);
    transition: all 0.5s;
    user-select: none;
  }
  .landing-page-features-card-report {
    display: block;
    overflow: hidden;
  }
  .landing-page-features-card-report-head-text {
    margin-bottom: 24px;
    font-size: 24px;
  }
  .landing-page-features-card-report-image {
    display: block;
    position: static;
    margin: 0 auto -32px auto;
    object-fit: contain;
    max-width: 720px;
    width: 100%;
    height: 100%;
  }
  .landing-page-features-card-report:hover
    .landing-page-features-card-report-image {
    transform: none;
    transition: none;
  }

  .landing-page-features-card-report .landing-page-features-card-report-action {
    color: var(--warning);
    background: var(--white);
    transition: all 0.5s;
  }
  .landing-page-features-card-report-text.max-w-450 {
    max-width: 100%;
  }
  .landing-page-features-card-soon-head-text {
    margin-bottom: 16px;
    font-size: 24px;
  }
  .landing-page-features-card-soon:hover
    .landing-page-features-card-soon-action {
    transform: none;
  }

  .landing-page-features-card-type,
  .landing-page-features-card-entity {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .landing-page-features-card-type-action,
  .landing-page-features-card-report-action {
    width: 100%;
    justify-content: center;
  }

  .landing-page-features .gl-scoring-bar-chart-bar-wrapper {
    width: calc(100% - 50px);
  }
}
</style>
