<template>
  <div :class="classesForBadgeInfo">
    <div
      v-tooltip="{
        content: text,
        show: badgeTooltip,
        trigger: 'manual',
        boundariesElement: 'body',
      }"
      class="badge-info__text"
      @mouseleave="badgeTooltip = false"
      @mouseover="badgeTooltip = true"
    >
      {{ text }}
    </div>
    <div 
      v-if="isValuePresent" 
      class="badge-info__value"
    >
      {{ calculateRiskLevel(value) }}
    </div>
  </div>
</template>

<script>
import { calculateRiskLevel } from '@/utils/redesign-report'

export default {
  props: {
    text: {
      type: String,
      default: 'Not Identified',
    },
    value: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      badgeTooltip: false,
    }
  },
  computed: {
    isValuePresent() {
      return this.value !== null && this.value !== undefined && (typeof this.value !== 'string' || this.value.trim() !== '')
    },
    classesForBadgeInfo() {
      let classes = [`badge-info`]

      classes.push(`badge-info--${this.calculateRiskLevel(this.value)}`)

      return classes
    }
  },
  methods: {
    calculateRiskLevel
  },
}
</script>

<style scoped>
.badge-info {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  max-width: 100%;
}
.badge-info__text {
  flex: 0 1 auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: var(--dark);
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.badge-info__value {
  flex: 0 0 auto;
  padding: 4px 8px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: var(--white);
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}
.badge-info--low .badge-info__value {
  background-color: rgba(112, 172, 63, 1);
}
.badge-info--medium .badge-info__value {
  background-color: rgba(252, 214, 48, 1);
  color: var(--black);
}
.badge-info--high .badge-info__value {
  background-color: rgba(242, 35, 92, 1);
}
</style>