import { render, staticRenderFns } from "./RiskExposureRow.vue?vue&type=template&id=6450bd16&scoped=true&"
import script from "./RiskExposureRow.vue?vue&type=script&lang=js&"
export * from "./RiskExposureRow.vue?vue&type=script&lang=js&"
import style0 from "./RiskExposureRow.vue?vue&type=style&index=0&id=6450bd16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6450bd16",
  null
  
)

export default component.exports