<template>
  <div class="risk-exposure">
    <RiskExposureHeader
      v-if="!fake"
      :sums-of-amount-and-price="sumsOfAmountAndPrice"
      :transactions="transactionsNumber"
      :type-of-funds="typeOfFunds"
    />
    <RiskExposureTable
      :exposure-data="exposureData"
      :fake="fake"
      :type-of-funds="typeOfFunds"
    />
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex'

// components
import RiskExposureHeader from './risk-exposure-components/RiskExposureHeader'
import RiskExposureTable from './risk-exposure-components/RiskExposureTable'

export default {
  components: {
    RiskExposureHeader,
    RiskExposureTable,
  },
  props: {
    riskLabelsData: {
      type: Object,
      default: () => {},
    },
    ownersData: {
      type: Object,
      default: () => {},
    },
    addressData: {
      type: Object,
      default: () => {},
    },
    typeOfFunds: {
      type: String,
      default: 'sof',
    },
    transactionsNumber: {
      type: Number,
      default: null,
    },
    fake: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveType']),
    exposureData() {
      if(this.getActiveType == 'owners') {
        return this.ownersData.data
      } else {
        return this.riskLabelsData.data
      }
    },
    sumsOfAmountAndPrice() {
      return this.exposureData?.reduce((acc, obj) => {
        acc.amount += obj?.amount || 0
        acc.price += obj?.price || 0
        return acc
      }, { amount: 0, price: 0 })
    },
  },
}
</script>

<style scoped>
.risk-exposure {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>