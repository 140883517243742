<template>
  <div class="risk-exposure-header">
    <div class="risk-exposure-header__left">
      <div class="risk-exposure-header__text">
        {{ headerType }}
      </div>
      <div class="risk-exposure-header__amount">
        {{ dataForAmount }}
      </div>
      <div class="risk-exposure-header__price">
        {{ formattedPrice }}
      </div>
    </div>
    <div class="risk-exposure-header__right">
      <div class="risk-exposure-header__text">
        Evaluated Transactions
      </div>
      <div class="risk-exposure-header__amount">
        {{ transactions }}
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapMutations } from 'vuex'

// utils
import { toComaSeparate } from '@/utils/formatNumber'
import { formatAmount } from '@/utils/format-amount'
import { formatByPrice } from '@/utils/format-by-price'

export default {
  props: {
    typeOfFunds: {
      type: String,
      default: 'sof',
    },
    sumsOfAmountAndPrice: {
      type: Object,
      default: () => {},
    },
    transactions: {
      type: Number,
      default: null,
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveToken']),
    headerType() {
      return this.typeOfFunds == 'sof' ? 'Source of Funds' : 'Use of Funds'
    },
    dataForAmount() {
      if (this.sumsOfAmountAndPrice && this.sumsOfAmountAndPrice.amount !== undefined) {
        return this.formatAmount(this.sumsOfAmountAndPrice.amount)
      }
      return 'N/A'
    },
    formattedPrice() {
      if (this.sumsOfAmountAndPrice && this.sumsOfAmountAndPrice.price !== undefined) {
        return this.formatByPrice(this.sumsOfAmountAndPrice.price)
      }
      return 'N/A'
    },
  },
  watch: {
    dataForAmount: {
      handler() {
        const collectedData = {
          amount: this.dataForAmount,
          price: this.formattedPrice,
          transactions: this.transactions,
        }
        if (this.typeOfFunds === 'sof') {
          this.SET_SOF_EXPOSURE_HEADER(collectedData || {})
        } else if (this.typeOfFunds === 'uof') {
          this.SET_UOF_EXPOSURE_HEADER(collectedData || {})
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('report', ['SET_SOF_EXPOSURE_HEADER', 'SET_UOF_EXPOSURE_HEADER']),
    toComaSeparate,
    formatAmount,
    formatByPrice,
  }
}
</script>

<style>
.risk-exposure-header {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.risk-exposure-header__left,
.risk-exposure-header__right {
  flex: 0 0 calc(50% - 8px);
}
.risk-exposure-header__right {
  text-align: right;
}
.risk-exposure-header__text {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
}
.risk-exposure-header__amount {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
}
.risk-exposure-header__price {
  margin-top: 4px;
  font-size: 10px;
  line-height: 1.5;
  color: rgba(89, 89, 89, 1);
}
</style>